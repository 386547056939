import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import NavbarSr from '../components/NavbarSr'
import FooterSr from '../components/FooterSr'

import ContactSr from '../components/ContactSr'

import Favicon from '../favicon-32x32.png'
// import ogMeta from '../img/an_og_index.jpg'
import ogMeta from '../scss/images/_02_1.jpg'


const InquryPageSr = ({ data }) => {
  const { wpPage: page } = data

  return (
    <div>
      {/* <Helmet title={`${page.title} | Interior Design Studio`} /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${page.title} | Interior Design Studio`}</title>
        <link rel="canonical" href={`https://ananenadovic.com/${page.slug}`} />

        <meta name="description" content={`${page.content}`} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
        <meta
          property="og:title"
          content={`${page.title} | Interior Design Studio`}
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://ananenadovic.com/${page.slug}`}
        />
        <meta name="robots" content="index" />
        <meta property="og:image" content={ogMeta} />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`${page.title} | Interior Design Studio`}
        />
        <meta name="twitter:description" content={`${page.content}`} />
        <meta name="twitter:image" content={ogMeta} />
      </Helmet>
      <NavbarSr />

      <ContactSr />
      {/* <InquryPageTemplate
        title={page.title}
        content={page.content}
        aboutUsData={data.aboutUs}
        homeElementsData={data.homeElements}
      /> */}
      <div className="stickTheFooter">
        <FooterSr />
      </div>
    </div>
  )
}

InquryPageSr.propTypes = {
  data: PropTypes.object.isRequired,
}

export default InquryPageSr

export const pageQuery = graphql`
  query PageByInqIdSr($id: String!) {
	wpPage(id: { eq: $id }) {
      title
      content
    }

    inq: wpPage(template: {templateName: {eq:  "Inquiry" }}) {
      id
      title
    }
  }
`
