import React, { Component } from 'react'
import axios from 'axios'
import { window, document } from 'browser-monads'

const API_PATH = 'https://ananenadovic.com/email_form_sr.php'

function inputReadFiles() {
  if (typeof window !== 'undefined');
  ;(function(window, index) {
    const inputs = document.querySelectorAll('.inputfile')
    Array.prototype.forEach.call(inputs, input => {
      const label = input.nextElementSibling
      const labelVal = label.innerHTML

      input.addEventListener('change', function(e) {
        let fileName = ''
        if (this.files && this.files.length > 1)
          fileName = (this.getAttribute('data-multiple-caption') || '').replace(
            '{count}',
            this.files.length
          )
        else fileName = e.target.value.split('\\').pop()

        if (fileName) label.querySelector('span.input_txt').innerHTML = fileName
        else label.innerHTML = labelVal
      })

      // Firefox bug fix
      input.addEventListener('focus', () => {
        input.classList.add('has-focus')
      })
      input.addEventListener('blur', () => {
        input.classList.remove('has-focus')
      })
    })
  })(window, 0)
}
class ContactOneSr extends Component {
  constructor(props) {
    super(props)
    this.state = {
	  name: '',
	  surname: '',
      email: '',
      deadline: '',
      address: '',
      area: '',
      selectedFiles: [],
      mailSent: false,
      error: null,
      window: '',
    }
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this)
    this.handleUserEmail = this.handleUserEmail.bind(this)
    // this.inputReadFiles = this.inputReadFiles(this)
    // this.deadlineHandler = this.deadlineHandler.bind(this)
  }

  //   componentDidMount() {
  //     this.inputReadFiles = setInterval(() => this.inputReadFiles(), 1000)
  //   }

  fileSelectedHandler = event => {
    console.log(event.target.files)
    this.setState({
      selectedFiles: event.target.files,
    })
  }
  //   deadlineHandler = event => {
  //     console.log(event.target.files)
  //     this.setState({
  //       selectedFiles: event.target.files,
  //     })
  //   }

  handleFormSubmit = e => {
    e.preventDefault()
    console.log(this.state)
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Methods': 'GET, POST',
      },
      data: this.state,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.mailSent,
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  //   // handle email
  handleUserEmail = event => {
    this.setState(
      {
        email: event.target.value,
      },
      function() {
        console.log(this.state.email)
      }
    )
  }

  render() {
    // const isEnabled = this.canBeSubmitted()
    setTimeout(() => {
      inputReadFiles()
    }, 700)

    return (
      <div className="contact_content">
        <form
          action="https://ananenadovic.com/email_form_sr.php"
          method="post"
          encType="multipart/form-data"
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
        >
          <div className="heroAboutUsTextGrid  form_grid">
			<div className="middle_element">
				<div className="topRightContent topRightInquiry top_text">
					<h1>ANA NENADOVIĆ ARHITEKTE</h1>
					{/* <div dangerouslySetInnerHTML={{ __html: whoWeAreSectionOne }} /> */}
					<div>
						Ukoliko imate projekat na kome biste želeli da radimo, molimo
						Vas da popunite formular ispod.
						<br />
						Molimo Vas da unesete lične podatke, adresu i površinu objekta u
						kvadratnim metrima.
						<br />
						Ukiliko postoji jasno definisan rok za početak i završetak
						projekta, molimo Vas da nam pošaljete i tu informaciju.
					</div>
				</div>
				<div className="topRightInputs middle_inputs">
					<label htmlFor="name">
						Ime i prezime *
						<input
						name="name"
						type="text"
						placeholder="Vaše ime"
						value={this.state.name}
						onChange={e => this.setState({ name: e.target.value })}
						required
						/>
						{/* <span className="error">{this.state.error.name}</span> */}
					</label>
					<label htmlFor="surname">
						Prezime *
						<input
						name="surname"
						type="text"
						placeholder="Vaše prezime"
						value={this.state.surname}
						onChange={e => this.setState({ surname: e.target.value })}
						required
						/>
						{/* <span className="error">{this.state.error.name}</span> */}
					</label>

					<label className="emailLabel" htmlFor="email">
						Vaš email *
						<input
						type="email"
						name="email"
						placeholder="Unesite validnu email adresu"
						value={this.state.email}
						// onChange={e => this.setState({ email: e.target.value })}
						onChange={this.handleUserEmail}
						required
						/>
						{/* <span className="error">{this.state.error.email}</span> */}
					</label>

					<label className="addressLabel" htmlFor="address">
						Adresa interijera
						<input
						name="address"
						type="text"
						placeholder="Unesite adresu enterijera"
						value={this.state.address}
						onChange={e => this.setState({ address: e.target.value })}
						// onChange={this.handleUserAddress}
						/>
						{/* <span className="error">{this.state.error.address}</span> */}
					</label>
					<label className="areaLabel" htmlFor="area">
						Površina enterijera u m2
						<input
						type="number"
						name="area"
						min="0"
						placeholder="Unesite površinu enterijera u m2"
						value={this.state.area}
						onChange={e => this.setState({ area: e.target.value })}
						/>
						{/* <span className="error">{this.state.error.area}</span> */}
					</label>
					</div>
				<div className="bottomLeftContent bottomLeftInquiry middle_text">
				{/* <div dangerouslySetInnerHTML={{ __html: whoWeAreSectionTwo }} /> */}
				Značile bi nam fotografije prostora koji se uredjuje kao i
				fotografije atmosfere koju želite da oživite novim enterijerom,
				što bi bili razni enterijeri koji su Vam se dopali iako ne moraju
				fizički da podsećaju na Vaš prostor, već da ukažu na ambijent kome
				težite.
				<br />
				</div>
				<div className="bottomRightInputs bottom_inputs">
				<div className="box">
					<input
					type="file"
					name="my_files[]"
					id="file-2"
					className="inputfile inputfile-2"
					data-multiple-caption="{count} odabranih fajlova"
					onChange={this.fileSelectedHandler}
					multiple
					/>
					<label htmlFor="file-2">
						<div className="labelWrap">
							<div className="svgWrap">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="17"
									viewBox="0 0 36 33"
									>
									<path fill="#000" fillOpacity=".5" d="M34.191 15.904c-.56 0-1.008.448-1.008 1.009v9.173a4.526 4.526 0 01-4.52 4.52H6.537a4.526 4.526 0 01-4.519-4.52v-9.323c0-.56-.448-1.008-1.009-1.008-.56 0-1.008.448-1.008 1.008v9.323a6.544 6.544 0 006.537 6.537h22.127a6.544 6.544 0 006.536-6.537v-9.173c0-.553-.448-1.009-1.009-1.009z"/>
									<path fill="#000" fillOpacity=".5" d="M11.9 8.135l4.691-4.691v20.46c0 .561.449 1.01 1.009 1.01.56 0 1.008-.449 1.008-1.01V3.445L23.3 8.135c.194.194.456.299.71.299a.972.972 0 00.71-.299 1.006 1.006 0 000-1.427L18.31.298A1.02 1.02 0 0017.6 0a.977.977 0 00-.71.299l-6.41 6.41a1.006 1.006 0 101.42 1.426z"/>
								</svg>
							</div>
							<div className="textBtnWrap">
								<span className="input_txt">Slike enterijera</span>
								<div className="info_about_upload">
									*Molimo vas da unesete do 5 fotografija koje su <br/>
									pojedinačno	manje od 1MB
								</div>
							</div>
						</div>
					</label>
				</div>
				<div className="box">
					<input
					type="file"
					name="atmosphere_files[]"
					id="atmosphere"
					className="inputfile inputfile-2"
					data-multiple-caption="{count} odabranih fajlova"
					placeholder="Molimo unesite fotografije atmosfere koja vas inspiriše"
					// onChange={this.fileSelectedOtherFiles}
					multiple
					/>
					<label htmlFor="atmosphere">
						<div className="labelWrap">
							<div className="svgWrap">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="17"
									viewBox="0 0 36 33"
									>
									<path fill="#000" fillOpacity=".5" d="M34.191 15.904c-.56 0-1.008.448-1.008 1.009v9.173a4.526 4.526 0 01-4.52 4.52H6.537a4.526 4.526 0 01-4.519-4.52v-9.323c0-.56-.448-1.008-1.009-1.008-.56 0-1.008.448-1.008 1.008v9.323a6.544 6.544 0 006.537 6.537h22.127a6.544 6.544 0 006.536-6.537v-9.173c0-.553-.448-1.009-1.009-1.009z"/>
									<path fill="#000" fillOpacity=".5" d="M11.9 8.135l4.691-4.691v20.46c0 .561.449 1.01 1.009 1.01.56 0 1.008-.449 1.008-1.01V3.445L23.3 8.135c.194.194.456.299.71.299a.972.972 0 00.71-.299 1.006 1.006 0 000-1.427L18.31.298A1.02 1.02 0 0017.6 0a.977.977 0 00-.71.299l-6.41 6.41a1.006 1.006 0 101.42 1.426z"/>
								</svg>
							</div>
							<div className="textBtnWrap">
								<span className="input_txt">Fotografije atmosfere</span>
								<div className="info_about_upload">
									*Molimo vas da unesete do 5 fotografija koje su<br/>
									pojedinačno manje od 1MB
								</div>
							</div>
						</div>
					</label>
				</div>

				<label className="deadlineLabel" htmlFor="deadline">
					Vaš rok
					<input
					type="text"
					name="deadline"
					placeholder="Unesite vaš rok"
					value={this.state.deadline}
					onChange={e => this.setState({ deadline: e.target.value })}
					// onChange={this.deadlineHandler}
					/>
				</label>
				</div>
			</div>
          </div>

          {/* //button upload/sendd */}
          <div className="sendButtonWrapper">
            <label>
              <input
                className="sendInput"
                type="submit"
                name="button"
                value="Pošalji"
              />
            </label>
          </div>
          <div className="contact_form_info">
            {this.state.mailSent && (
              <div>
                Hvala na vašem upitu. Kontaktiracemo vas uskoro!
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }
}

export default ContactOneSr
