import React, { Component } from 'react'
import ContactSr from './email_form/ContactFormSr'
// import TestImage from '../scss/images/_02_1.jpg'
import TestImage from '../scss/images/inquiry_new_c.jpg'

class ContactPartSr extends Component {
  render() {
    return (
      <div className="inquiryPageWrapper section section--gradient">
        <div className="heroAboutUsContentHolder heroInquiryHolder">
          <div className="heroAboutUsImageHolder">
            <div className="customWrapper">
              <div className="bottomHalfText">
                <div className="whitetitleHolder">
                  <h1 className="whiteTitleHalf">UPIT</h1>
                </div>
              </div>
              <div
                className="blendModeHolder"
                style={{ backgroundImage: `url(${TestImage})` }}
              >
                <div className="topHalfText">
                  <h1 className="coloredTitleHalf">UPIT</h1>
                </div>
              </div>
            </div>
            <img src={TestImage} alt="" />
          </div>
        </div>
        <section className="contactFormInquiry" id="contactInquiry">
          <div className="inquiry_wrapper">
            <ContactSr />
          </div>
        </section>
      </div>
    )
  }
}

export default ContactPartSr
